<template>
  <div class="main-view">
    <el-radio-group v-model="table.params.status" class="tabsWrap" @change="handleTabs">
      <el-radio-button label="">全部</el-radio-button>
      <!-- 总管理端把该账号分拣功能关闭了,则订单下单后直接进入待配送状态，不显示待分拣状态 (1-开启；2-关闭)-->
      <el-radio-button label="-1" v-if="open_pick == 1">待分拣({{ table.sorter_total}})</el-radio-button>
      <el-radio-button :label="1">待配送({{ table.init_total }})</el-radio-button>
      <el-radio-button :label="2">待送达({{ table.confirm_total }})</el-radio-button>
      <el-radio-button :label="3">已完成({{ table.finish_total }})</el-radio-button>
      <el-radio-button :label="4">已取消({{ table.cancel_total }})</el-radio-button>
    </el-radio-group>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="配送日期" prop="distr" v-if="table.params.status != 4">
          <el-date-picker
            v-model="table.params.distr"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            class="selDaterangeWidth"
            :picker-options="pickerOptions"
             @change="onSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="商品名称" prop="good_name">
          <el-input
            size="mini"
            v-model.trim="table.params.good_name"
            placeholder="请输入商品名称"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户名称" prop="merchant_id">
          <el-select v-model="table.params.merchant_id" filterable placeholder="请选择" ref="elementSelect" @input.native="trimInput" clearable @focus="getMerchantSel" @change="changeMerchant">
            <el-option
              v-for="item in merchantArr"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="食堂名称" prop="address_id">
          <el-select v-model="table.params.address_id" filterable placeholder="请输入" clearable @focus="getStoreSel" @change="onSearch">
            <el-option v-for="item in storeArr" :key="item.id" :label="item.name" :value="item.id">
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">({{ item.merchant_name }})</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品分类" prop="cate_id">
          <el-select v-model="table.params.cate_id" filterable placeholder="请选择" clearable @change="onSearch">
            <el-option
              v-for="item in goodsTypeArr"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下单分类" prop="order_cate_arr">
          <el-cascader
            v-model="table.params.order_cate_arr"
            filterable placeholder="请选择"
            id="order_cate_arr"
            @change="changeOrderCateId"
            @focus="getOrderCateSel"
            :options="orderCateArr"
            :props="{ children: 'child',label: 'name',value:'id', checkStrictly: true }"
            clearable></el-cascader>
        </el-form-item>
        <el-form-item label="订单编号" prop="order_no">
          <el-input
            size="mini"
            v-model.trim="table.params.order_no"
            placeholder="请输入订单编号"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="下单账号" prop="phone">
          <el-input
            size="mini"
            v-model.trim="table.params.phone"
            placeholder="请输入下单账号"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="下单日期" prop="date">
          <el-date-picker
            v-model="table.params.date"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            class="selDaterangeWidth"
            :picker-options="pickerOptions"
            @change="onSearch">
          </el-date-picker>
        </el-form-item>
        <!-- 超级管理员才有地区查询条件 -->
        <el-form-item label="地区" prop="area" v-if="roleType == 1">
          <el-cascader
            v-model="table.params.area"
            :options="areaArr"
            :props="{checkStrictly: true }"
            clearable
            filterable
            style="width:100%;"
            @change="onSearch">
          </el-cascader>
        </el-form-item>
        <el-form-item label="审核状态" prop="is_check">
          <el-select v-model="table.params.is_check" clearable @change="onSearch">
            <el-option label="未审核" :value="2"></el-option>
            <el-option label="已审核" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch" >搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button
          :size="subUnitSize"
          type="primary"
          icon="el-icon-plus"
          @click="showAdd"
          >添加订单</el-button>
        <el-button
          v-if="table.params.status == 1"
          :size="subUnitSize" 
          type="primary" 
          plain
          icon="el-icon-circle-check"
          @click="handleBatch"
          >批量分配</el-button
        >
        <el-button
          :size="subUnitSize"
          type="primary"
          plain
          icon="el-icon-download"
          @click="exportData('/admin/order/export')"
          >全部导出</el-button
        >
        <el-button
          :size="subUnitSize"
          type="primary"
          plain
          @click="plexportData('/admin/order/export')"
          >批量导出</el-button
        >
        <el-button
          v-if="table.params.status != 4"
          :size="subUnitSize"
          type="primary"
          plain
          @click="mergeexportData('/admin/order/mergeExport')"
          >合并导出</el-button
        >
        <!--全部、待分拣、待配送、配送中、已完成状态可以导发货单 ,已完成的改“导出定稿单”-->
        <el-button
          v-if="table.params.status != 4"
          type="primary"
          plain
          :size="subUnitSize"
          @click="mergeexportDataTem"
          >{{table.params.status == 3 ? '导出定稿单' : '导出发货单'}}</el-button>
        <!-- 总管理端把该账号分拣功能关闭了,则没有批量分拣功能 (1-开启；2-关闭)-->
        <el-button type="warning" plain :size="subUnitSize" v-if="open_pick == 1 && table.params.status == -1" @click="sortingBatch">批量分拣</el-button>
        <el-button type="warning" plain :size="subUnitSize" v-if="table.params.status == 1" @click="distrBatch">批量配送</el-button>
        <el-button type="warning" plain :size="subUnitSize" v-if="table.params.status == 2" @click="sendBatch">批量送达</el-button>
        <!-- 全部和待分拣 才有批量审核, 不支持待分拣的在带配送状态列表也加批量审核操作 -->
        <el-button type="warning" plain :size="subUnitSize" v-if="table.params.status < 1 || (table.params.status == 1 && open_pick == 2) " @click="checkBatch">批量审核</el-button>
        <el-button type="primary" plain :size="subUnitSize" v-if="table.params.status == ''" @click="exportText">导出文本</el-button>
      </div>
    </div>
    <div class="curTableWrap" ref="main">
      <el-table
        :key="checkType"
        :data="table.data"
        ref="tableDom"
        :height="height"
        border
        size="mini"
        style="width: 100%"
        v-loading="table.loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0)"
        @selection-change="handleSelectionChange"
        :header-cell-style="{background:'#f5f5f5'}"
        class="chTable"
      >
        <el-table-column v-if="checkType == 2" type="selection" width="50" :selectable="selectEnable"></el-table-column>
        <el-table-column v-if="checkType == 1" type="selection" width="50" ></el-table-column>
        <el-table-column
          label="订单编号"
          prop="order_no"
          width="145px">
          <template slot-scope="{row}">
            <el-link type="primary" @click="showDetail(row.id)">{{row.order_no}}</el-link>
          </template>
        </el-table-column>
        <el-table-column
          label="客户名称"
          prop="merchant_name"
          width="150px">
        </el-table-column>
        <el-table-column
          label="下单食堂"
          prop="store_name"
          width="150px">
        </el-table-column>
        <el-table-column
          label="商品项数"
          prop="details">
          <template slot-scope="{row}">
            <span>{{row.details.length}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="下单数量"
          prop="spec_count">
        </el-table-column>
        <el-table-column
          label="金额/元"
          prop="amount">
        </el-table-column>
        <el-table-column
          label="状态"
          prop="status"
          width="90px">
          <template slot-scope="{row}">
            <!-- 待分拣状态中并且有分拣数量sorter_num就是（部分分拣） -->
            <div v-if="(!!row.sorter_num || row.sorter_num > 0) && row.status == 0">
              部分分拣
            </div>
            <div v-else>
              <span>{{toStr({0:'待分拣',1:'待配送',2:'待送达',3:'已完成',4:'已取消'},row.status)}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="配送日期"
          prop="distr_date"
          width="120">
          <template slot-scope="{row,$index}">
            <span v-if="row.status > 1">{{row.distr_date}}</span>
            <!-- 待分拣、待配送的配送日期请用蓝色字体，点击可以修改其他日期 -->
            <el-popover
              v-else
              :ref="`popoverDom+${$index}`"
              placement="bottom"
              width="220"
              trigger="click">
              <el-form
                class="staff-from"
                :model='form.data'
              >
                <el-form-item label="修改配送日期" prop="distr_date">
                  <el-date-picker
                    v-model="form.data.distr_date"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                    format="yyyy-MM-dd">
                  </el-date-picker>
                </el-form-item>
              </el-form>
              <el-row type="flex" justify="center">
                <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click="handleDate(row,$index)">确定</el-button>
              </el-row>
              <el-button slot="reference" type="text" style="color:#409eff" icon="el-icon-edit-outline">{{row.distr_date}}</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          label="下单时间"
          prop="create_time"
          width="150">
        </el-table-column>
        <el-table-column
          v-if="table.params.status != -1"
          label="配送员"
          prop="rider_name"
          width="96px">
          <template slot-scope="{row}">
            <el-button :style="{color: !row.rider_name ? '#409eff' :''}" v-if="row.status == 1" type="text" @click="showEdit(row)">{{row.rider_name ? row.rider_name :'未绑定'}}</el-button>
            <span v-else type="text">{{row.rider_name}}</span>
          </template>
        </el-table-column>
        <!--待分拣状态的订单操作：修改数量、分拣、更多
            待配送的：修改数量、开始配送、更多
            待送达：修改数量、确认送达、更多
            已完成：修改数量、导出、更多
            已取消不用更多 -->
        <el-table-column
          label="操作"
          prop="action"
          fixed="right"
          :width="table.params.status == 4 ? '130px' : '220px'">
          <template slot-scope="{row}">
            <!-- status 0:（待分拣，有分拣数量sorter_num就是部分分拣）, 1:'待配送',2:'待送达',3:'已完成',4:'已取消'。 is_check：1已审核，2未审核 -->
            <!-- 待分拣且分拣数量为0，并且未审核的才有审核操作按钮，部分分拣和其他状态都没有审核操作按钮 -->
            <el-button v-if="(row.status == 0 && row.sorter_num == 0) && row.is_check == 2" type="text" style="color:#e6a23c;" size="mini" @click="handleCheck(row)">审核</el-button>
            <!-- open_pick == 2，不支持待分拣，在待配送列表也增加审核和批量审核按钮 -->
            <el-button v-if="(row.status == 1 && open_pick == 2) && row.is_check == 2" type="text" style="color:#e6a23c;" size="mini" @click="handleCheck(row)">审核</el-button>
            <el-button v-if="row.status !== 4" type="text" size="mini" @click="handleQuantity(row)">修改数量</el-button>
            <el-button v-if="row.status == 1" type="text" size="mini" @click="handleDistr(row)">开始配送</el-button>
            <el-button v-if="row.status == 2" type="text" size="mini" @click="handleSend(row)">确认送达</el-button>
            <el-button type="text" size="mini" v-if="row.status == 3" @click="singleExport(row)">导出</el-button>
            <template v-if="row.status == 4">
              <el-button type="text" size="mini" @click="showAdd(row)">复制</el-button>
              <el-button type="text" size="mini" @click="singleExport(row)">导出</el-button>
              <!-- 超级管理员才有删除操作 -->
              <el-button v-if="roleType == 1" type="text" size="mini" @click="remove(row)">删除</el-button>
            </template>
            
            <!-- <el-button v-if="row.status == 0 || row.status == 1" type="text" size="mini" @click="handleCancel(row)">取消</el-button> -->
            <el-button v-if="row.status == 0" type="text" size="mini" @click="showSorting(row)">分拣</el-button>
            <!-- <el-button v-if="row.status == 0 || row.status == 1" type="text" size="mini" @click="showRepair(row)">补单</el-button> -->

            <el-dropdown @command="handleCommand($event,row)" style="margin-left:10px" v-if="row.status != 4" >
              <span class="el-dropdown-link">
                更多<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="copy">复制</el-dropdown-item>
                <el-dropdown-item command="export">导出</el-dropdown-item>
                <el-dropdown-item command="repair" v-if="row.status == 0 || row.status == 1">补单</el-dropdown-item>
                <el-dropdown-item command="cancel" v-if="row.status == 0 || row.status == 1">取消</el-dropdown-item>
                <el-dropdown-item command="remove" v-if="roleType == 1">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>

      <div class='pagination__wrap'>
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :current-page="table.params.page"
          :page-size="table.params.count"
          :total="table.total"
          :page-sizes="[10, 20, 50, 100, 150]"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 分配配送员 -->
    <el-dialog
      title="分配配送员"
      :visible.sync="riderDialogVisible"
      width="400px"
      :before-close="handleClose">
      <el-form
        class="staff-from"
        element-loading-text="加载中…"
        ref='elFormDomRider'
        label-width='100px'
        label-position='right'
        :model='riderForm.data'
        :rules='riderForm.rules'
        :size="subUnitSize"
      >
        <el-form-item label="配送员" prop="rider_id">
          <el-select v-model="riderForm.data.rider_id" @focus="getRider" placeholder="请选择" class="width100">
            <el-option
              v-for="item in riderArr"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitRider">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 上传凭证 -->
    <el-dialog
      title=""
      :visible.sync="dialogVisibleBill"
      width="500px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      center>
      <el-form
        class="staff-from"
        element-loading-text="加载中…"
        ref='elFormDomBill'
        label-width='110px'
        label-position='top'
        :model='formBill.data'
        :rules='formBill.rules'
        :size="subUnitSize"
      >
        <el-form-item label="上传签字确认单(1-3张)" prop="bills">
          <el-upload
            action="/api/common/qiniu/upload"
            :limit="3"
            list-type="picture-card"
            class="imgWrap"
            :headers="{ token: token }"
            :on-preview="handlePictureCardPreview"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :on-remove="handleRemove"
            :file-list="fileList"
            accept="image/jpeg, image/png, image/gif"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisibleUrl" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt />
          </el-dialog>
        </el-form-item>
      </el-form>
      <div>
        <div style="font-size:14px;font-weight:bold;">凭证将展示以下订单详情内</div>
        <div v-for="(item,index) in tableData" :key="index" style="border-bottom:solid 1px #eee;padding-bottom: 10px;">
          <div style="font-size: 14px;font-weight: bold;margin-top: 10px;">【{{item.merchant_name}}】{{item.store_name}}</div>
          <div style="display: flex;justify-content: space-between;margin-top: 10px;color: #999;">
            <span>订单编号：{{item.order_no}}</span>
            <span>共{{item.goods_count}}类，{{item.spec_count}}件</span>
          </div>
        </div>
      </div>
      <template #footer>
        <el-row type="flex" justify="center">
          <el-button type="primary" :loading="formBill.loading" @click="sumbitBill">确定</el-button>
        </el-row>
      </template>
    </el-dialog>

    <!-- 订单审核 -->
    <el-dialog
      :title="checkType == 2 ? '批量审核' : '审核订单'"
      :visible.sync="dialogVisibleCheck"
      width="500px"
      :before-close="handleClose2"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      center>
      <div style="font-size:14px;">
        <div class="mt10">审核后订单内商品将会进入采购审核进行汇总</div>
        <template v-if="checkType == 2">
          <div class="mt10">食堂数量：{{check_addressNUm.length}}</div>
          <div class="mt10">订单笔数：{{multipleSelection.length}}</div>
          <div class="mt10">商品项数合计：{{check_detailsNum}}</div>
          <div class="mt10">下单数量合计：{{check_spec_count}}</div>
        </template>
        <template v-else>
          <div class="mt10">食堂名称：{{currRow.store_name}}（{{currRow.merchant_name}}）</div>
          <div class="mt10">订单编号：{{currRow.order_no}}</div>
          <div class="mt10">商品项数：{{currRow.details.length}} </div>
          <div class="mt10">下单数量：{{currRow.spec_count}}</div>
        </template>
      </div>
      <template #footer>
        <el-row type="flex" justify="center">
          <el-button type="primary" plain @click="handleClose2">取消</el-button>
          <el-button type="primary" :loading="formBill.loading" @click="sumbitCheck">确认审核</el-button>
        </el-row>
      </template>
    </el-dialog>

    <!-- 导出发货单 -->
    <el-dialog
      title="导出发货单"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      center>
      <div style="margin-bottom: 20px;">请选择导出模板：</div>
      <div style="display:flex;justify-content: space-evenly;">
        <div class="item" v-for="item in tmp" :key="item.id">
          <div @click="showTmp(item)">
            <el-card shadow="hover" :class="item.id == tpl_id ? 'item-card tpl-action' : 'item-card'">{{item.name}}</el-card>
          </div>
          <el-radio v-model="tpl_id" :label="item.id">选择</el-radio>
        </div>
      </div>
      <el-dialog width="70%" :visible.sync="dialogVisibleImage" append-to-body>
        <img width="100%" :src="dialogImageUrl" alt />
      </el-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </span>
    </el-dialog>

    <!-- 新增订单 -->
    <add ref="add" @refresh="getTable" :sampleFile="sampleFile" :const_merchant_id="const_merchant_id" :merchantArr="merchantArr"></add>
    <!-- 分配配送员 -->
    <edit ref="edit" @refresh="getTable"></edit>
    <!-- 查看详情 -->
    <detail ref="detail" @refresh="getTable"></detail>
    <!-- 补单 -->
    <repair ref="repair" :sampleFile="sampleFile" :const_merchant_id="const_merchant_id" :merchantArr="merchantArr" @refresh="getTable"></repair>
    <!-- 修改数量 -->
    <Quantity ref="quantity" @refresh="getTable"></Quantity>
    <!-- 按客户分拣详情 -->
    <ClientSorting ref="ClientSorting" @refresh="getTable"></ClientSorting>
  </div>
</template>

<script>
import Add from "./components/Add.vue";
import Edit from "./components/Edit.vue";
import Detail from '@/layout/OrderDetail.vue';
import Quantity from './components/Quantity.vue';
import Repair from './components/Repair.vue';
import ClientSorting from '@/views/sorting/commodity/components/ClientSorting.vue';
import VTable from "@/components/VTable";
import { mixinTable } from "@/mixins/table.js";
import { mapGetters } from 'vuex'
import { getStorage } from '@/storage'

export default {
  name: "OrderListIndex",
  components: {
    Add,
    Edit,
    Detail,
    Quantity,
    Repair,
    ClientSorting,
    VTable,
  },
  mixins: [mixinTable],
  computed:{
    ...mapGetters(['goodsTypeArr','areaArr'])
  },
  data() {
    return {
      const_merchant_id: '', // 省委党校商户id
      roleType: getStorage('role_type'),// 1:超级管理员，2:管理员, 3：员工
      open_pick: getStorage('open_pick') || 1,// 是否开启分拣功能：1-开启；2-关闭
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      multipleSelection: [],
      popoverVisible: false,
      height: 0,
      form:{
        data:{
          distr_date: '',
        },
        rules:{
          distr_date: [{required: true, message: '请选择配送日期', trigger: 'change' }]
        }
      },
      table: {
        loading: false,
        params: {
          // status: getStorage('open_pick') == 1 ? '-1' : '', //状态：-1:待分拣; 1-待配送；2-待送达；3-已完成；4-已取消；
          status: '', // 空的时候是查全部状态
          // status: 2, // 空的时候是查全部状态
          good_name:'',
          cate_id:'',
          order_no:'',
          order_cate_arr: [],
          order_cate_id: '',
          order_cate_tow: '',
          phone:'',
          create_start: '',
          create_end: '',
          date: '',
          distr: [],// 配送日期
          distr_start: '',
          distr_end: '',
          merchant_id: '', // 客户id
          address_id: '', // 食堂名称
          area: [],
          province: '',
          city: '',
          is_check: '', //是否审核 1:是 2:否
          page: 1,
          count: 10,
        },
        data: [],
        total: 0,
        sorter_total: 0,// 待分拣
        init_total: 0,
        confirm_total: 0,
        finish_total: 0,
        cancel_total: 0,
      },
      orderCateArr: [], // 下单分类
      merchantArr: [],
      storeArr: [],
      dialogVisible: false,
      styleType: 1,
      tpl_id: '', // 模板id
      tmp: {},
      dialogVisibleImage: false,
      dialogImageUrl: '',
      isAdd: false,
      riderArr:[],// 配送员
      riderDialogVisible: false, // 配送员弹窗
      riderForm: {
        loading: false,
        data: {
          rider_id: '',
        },
        rules:{
          rider_id: [{required: true, message: '请选择配送员', trigger: 'change' }]
        }
      },
      tableData:[],
      sendType:1, // 1：单条送达，2：批量送达
      dialogVisibleBill:false, //确认送达上传凭证
      formBill: {
        loading: false,
        data: {
          bills:'', //单据
        },
        rules:{
          bills: [{ required: true, message:'请上传', trigger: 'change'}],
        }
      },
      fileList: [],
      dialogImageUrl: '', 
      dialogVisibleUrl: false, 
      sampleFile: '', // 添加订单批量导入样表
      dialogVisibleCheck: false,
      checkType: 1, // 1：单条审核，2：批量审核
      currRow: {
        order_no: '',
        merchant_name: '',
        details: '',
        spec_count: '',
      },
      check_addressNUm: 0, // 批量审核时食堂数量（相同食堂去重）
      check_detailsNum: 0, // 批量审核时商品项数合计
      check_spec_count: 0, // 批量审核时下单数量合计

      textData: '',

    };
  },
  created() {
    const start = new Date();
    const end = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 1); // 状态
    end.setTime(end.getTime() + 60 * 60 * 24 * 1000); // 明天
    this.table.params.distr[0] = this.$moment(start).format("YYYY-MM-DD");
    this.table.params.distr[1] = this.$moment(end).format("YYYY-MM-DD");
    this.getTable();
    this.getMerchantSel(); //客户列表
  },
   mounted() {
    this.$nextTick(() => {
      this.height = this.$refs.main.offsetHeight || 500
    })
    // 价格处理商户、门店接口
    this.$http.get('/common/constant/address',{params:{}}).then(res => {
      console.log(res.data)
      this.const_merchant_id = res.data.merchant[0]; // 省委党校id
    })
  },
  methods: {
    getTable() {
      let _params = {...this.table.params}
      let _date = _params.date;
      if(_date &&  _date.length > 0) {
        _params.create_start = _date[0]
        _params.create_end = _date[1]
      } else {
        _params.create_start = '';
        _params.create_end = '';
      }
      let _distr = _params.distr;
      if(_distr &&  _distr.length > 0 && _params.status != 4) {
        _params.distr_start = _distr[0]
        _params.distr_end = _distr[1]
      } else {
        _params.distr_start = '';
        _params.distr_end = '';
      }
      let _area = _params.area;
      if(_area.length == 1) {
        _params.province = _area[0]
      } else if(_area.length == 2) {
        _params.province = _area[0]
        _params.city = _area[1]
      } else {
        _params.province = '';
        _params.city = '';
      }
      delete _params.distr;
      delete _params.date;
      delete _params.area;
      delete _params.order_cate_arr;
      this.table.loading = true;
      this.$http
        .post("/admin/order/list", _params)
        .then((res) => {
          if (res.code === 1) {
            this.table.loading = false;
            this.table.data = res.data.list;
            this.table.total = res.data.total;
            this.sampleFile = res.data.file; // 添加订单批量导入样表
            this.table.sorter_total = res.data.sorter_total; // 待分拣
            this.table.init_total = res.data.init_total;
            this.table.confirm_total = res.data.confirm_total;
            this.table.finish_total = res.data.finish_total;
            this.table.cancel_total = res.data.cancel_total;
          }
        });
    },
    reset() {
      this.table.params = {
        good_name:'',
        cate_id:'',
        order_no:'',
        order_cate_arr: [],
        order_cate_id: '',
        order_cate_tow: '',
        phone:'',
        create_start: '',
        create_end: '',
        date: '',
        distr: [],// 配送日期
        distr_start: '',
        distr_end: '',
        merchant_id: '', // 客户id
        address_id: '', // 食堂名称
        area: [],
        province: '',
        city: '',
        is_check: '', //是否审核 1:是 2:否
        status: ''
      };
      this.table.params.page = 1;
      this.table.params.count = 10;
      this.getTable();
    },
    // 全部导出
    exportData(url) {
      let str = ""
      let obj = { ...this.table.params }
      let _date = obj.date;
      if(_date &&  _date.length > 0) {
        obj.create_start = _date[0]
        obj.create_end = _date[1]
      } else {
        obj.create_start = '';
        obj.create_end = '';
      }
      let _distr = obj.distr;
      if(_distr &&  _distr.length > 0) {
        obj.distr_start = _distr[0]
        obj.distr_end = _distr[1]
      } else {
        obj.distr_start = '';
        obj.distr_end = '';
      }
      let _area = obj.area;
      if(_area.length == 1) {
        obj.province = _area[0]
      } else if(_area.length == 2) {
        obj.province = _area[0]
        obj.city = _area[1]
      } else {
        obj.province = '';
        obj.city = '';
      }
      delete obj.date
      delete obj.distr
      delete obj.area
      delete obj.order_cate_arr;
      Object.keys(obj).forEach(item => {
        str += `&${item}=${obj[item]}`
      })
      console.log(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
      window.open(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
    },
    // 批量导出
    plexportData(url) {
      if (this.multipleSelection.length) {
        let ids = this.multipleSelection
          .map((item) => {
              return item.id;
          }).join(",");
        let str = ""
        let obj = {
          ...this.table.params,
          ids
        }
        let _date = obj.date;
        if(_date &&  _date.length > 0) {
          obj.create_start = _date[0]
          obj.create_end = _date[1]
          delete obj.date
        } else {
          obj.create_start = '';
          obj.create_end = '';
        }
        let _distr = obj.distr;
        if(_distr &&  _distr.length > 0 && obj.status != 4) {
          obj.distr_start = _distr[0]
          obj.distr_end = _distr[1]
        } else {
          obj.distr_start = '';
          obj.distr_end = '';
        }
        let _area = obj.area;
        if(_area.length == 1) {
          obj.province = _area[0]
        } else if(_area.length == 2) {
          obj.province = _area[0]
          obj.city = _area[1]
        } else {
          obj.province = '';
          obj.city = '';
        }
        delete obj.date
        delete obj.distr
        delete obj.area
        delete obj.order_cate_arr;
        Object.keys(obj).forEach(item => {
          str += `&${item}=${obj[item]}`
        })
        console.log(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
        window.open(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
      } else {
        this.$message.warning("至少勾选一条数据");
      }
    },
    // 获取配送员
    getRider() {
      this.$http.post('/admin/admin/riderList',{page:1,count:1000}).then(res => {
        if(res.code == 1) {
          this.riderArr = res.data;
        }
      })
    },
    // 添加订单
    showAdd(row) {
      let dom = this.$refs.add;
      dom.toggle(true);
      dom.getDetail(row);
      dom = null;
    },
    // 分配配送员
    showEdit(row) {
      let dom = this.$refs.edit;
      dom.toggle(true);
      if (!!row.id) {
        dom.getDetail(row);
      }
      dom = null;
    },
    // 补单
    showRepair(row) {
      let dom = this.$refs.repair;
      dom.toggle(true);
      dom.getDetail(row);
      dom = null;
    },
    // 查看
    showDetail(orderId) {
      let dom = this.$refs.detail;
      dom.toggle(true);
      dom.getDetail(orderId);
      dom = null;
    },
    // 按客户分拣
    showSorting(row) {
      let dom = this.$refs.ClientSorting;
      dom.toggle(true);
      let _row = {
        ...row,
        order_id: row.id, // 订单id
        sum_quantity: row.spec_count, // 商品数量
      }
      dom.getDetail(_row, 2); // 传参2是按客户分拣
      dom = null;
    },
    // 修改数量
    handleQuantity(row) {
      //线上支付的订单不支持帮客户修改数量和补单 order_type订单类型 1:支付订单 0:非支付订单
      if(row.order_type == 0) {
        let dom = this.$refs.quantity;
        dom.toggle(true);
        dom.getDetail(row);
        dom = null;
      } else {
        this.$message.warning('线上支付订单不支持操作')
      }
    },
    // 下架
    view(data) {
      this.setConfirm(`请确认下架？`, { id: data.id }, "/admin/apply/check");
    },
    handleClick(row) {
      this.$message.success(row.id)
    },
    // 单条确认
    handleConfirm(row) {
      this.confirmFun(row.id)
    },
    confirmFun(ids) {
      this.$confirm('确认仓库备货', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(ids)
        this.$http.post('/admin/order/confirm',{id: ids}).then(res => {
          if(res.code == 1) {
            this.$message.success('操作成功！')
            this.getTable();
          }
        })
      })
    },
    // 取消订单
    handleCancel(row) {
      this.$confirm('请确认取消？','提示',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$http.post("/admin/order/cancel",{id: row.id}).then(res => {
          if(res.code == 1) {
            //is_check是否审核 1:是 2:否 如果销售订单内的商品已经生成了采购单，再去取消/删除订单则提示
            if(row.is_check == 1) {
              this.$message.success("操作成功，订单内商品已下采购建议您及时检查采购单并进行修改")
            } else {
              this.$message.success("操作成功")
            }
            this.getTable();
          }
        })
      }).catch(() => {})
    },

    // 批量分配配送员
    handleBatch() {
      if(this.multipleSelection.length > 0) {
        let ids = this.multipleSelection.map((item) => {
          return item.id
        }).join(",")
        console.log(ids)
        let _data = {
          id: ids
        }
        this.showEdit(_data)
      } else {
        this.$message.warning('至少勾选一条数据！')
      }
    },
    // 单条订单导出
    singleExport(row) {
      this.multipleSelection = [row];
      this.plexportData('/admin/order/export')
      this.multipleSelection = [];
    },
    
    // 关闭
    handleClose() {
      this.dialogVisible = false;
      this.riderDialogVisible = false;
      this.dialogVisibleBill = false; // 凭证
      this.fileList = [];
    },
    handleClose2() {
      this.dialogVisibleCheck = false;
      this.checkType = 1; // 审核方式默认1
      this.multipleSelection = [];
    },
    showTmp(item) {
      this.tpl_id = item.id; // 模板id
      this.dialogVisibleImage = true;
      this.dialogImageUrl = item.image;
    },
    // 判断是否同一食堂
    addressCheckIdsSame(arr) {
      if(arr.length === 0) {
        // 如果数组为空直接返回true
        return true
      }
      const firstId = arr[0].address_id
      // 遍历数组
      for(let i = 1; i < arr.length; i++){
        // 如果遇到id不相同的元素返回false
        if(arr[i].address_id !== firstId) {
          return false
        }
      }
      // 所有元素id相同返回true
      return true;
    },
    // 判断是否同一客户
    merchantCheckIdsSame(arr) {
      if(arr.length === 0) {
        // 如果数组为空直接返回true
        return true
      }
      const firstId = arr[0].merchant_id; // 客户id
      // 遍历数组
      for(let i = 1; i < arr.length; i++){
        // 如果遇到id不相同的元素返回false
        if((arr[i].merchant_id !== firstId)) {
          return false
        }
      }
      // 所有元素id相同返回true
      return true;
    },
    // 合并导出
    mergeexportData(url) {
      if (this.multipleSelection.length) {
        console.log(this.addressCheckIdsSame(this.multipleSelection))
        if(!this.addressCheckIdsSame(this.multipleSelection)) {
          return this.$message.warning("只能选择同一食堂导出")
        }
        this.$confirm('合并导出订单将会把相同的商品名称、规格、单价合并在一行数据统计', '提示', {
          confirmButtonText: '确认导出',
          cancelButtonText: '取消',
        }).then(() => {
          let order_ids = this.multipleSelection.map((item) => {
            return item.id;
          }).join("|");
          let str = ""
          let obj = {
            order_ids
          }
          Object.keys(obj).forEach(item => {
              str += `&${item}=${obj[item]}`
          })
          console.log(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
          window.open(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)

        }).catch(() => {});
      } else {
        this.$message.warning("至少勾选一条数据");
      }
    },
    // 导出发货单模板
    mergeexportDataTem() {
      if (this.multipleSelection.length) {
        // 导出发货单模板需同一个客户,最多只能选31天的订单，超出31天则提示
        if(!this.merchantCheckIdsSame(this.multipleSelection)) {
          return this.$message.warning("只能选同一个客户下的订单")
        }
        let arr = Array.from(new Map(this.multipleSelection.map(item => [item.distr_date,item])).values()); //同一配送日期去重
        if(arr.length > 31) {
          return this.$message.warning("导出发货单最多可选31天，已超出")
        }
        let is_tpl = this.multipleSelection.filter(item => item.is_tpl == 0); // is_tpl绑定模板 1:是 0:否
        if(is_tpl.length > 0) {
          this.$message.warning("请先在发货单模版为该客户绑定对应样式")
        } else {
          let _merchant_ids = this.multipleSelection.map((item) => {
            return item.merchant_id
          })[0]
         
          this.$http.get('/admin/order/getTmp',{params:{merchant_id: _merchant_ids}}).then(res => {
            if(res.code == 1) {
              if(res.data.length > 1) { // 该客户有多个模板时弹窗选择
                this.dialogVisible = true;
                this.tmp = res.data;
              } else { // 一个模板时直接导出
                let order_ids = this.multipleSelection
                  .map((item) => {
                      return item.id;
                  }).join("|");
                let str = ""
                let obj = {
                  order_ids,
                  tpl_id: res.data[0].id, // 模板id
                }
                Object.keys(obj).forEach(item => {
                  str += `&${item}=${obj[item]}`
                })
                let url = '/admin/excel/export';
                console.log(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
                window.open(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
              }
            }
          })
        }
      } else {
        this.$message.warning("请选择订单");
      }
    },
    // 删除
    remove(row) {
      this.$confirm('确认删除操作？','提示',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$http.post("/admin/order/del",{id: row.id}).then(res => {
          if(res.code == 1) {
            //is_check是否审核 1:是 2:否 如果销售订单内的商品已经生成了采购单，再去取消/删除订单则提示
            if(row.is_check == 1) {
              this.$message.success("操作成功，订单内商品已下采购建议您及时检查采购单并进行修改")
            } else {
              this.$message.success("操作成功")
            }
            this.getTable();
          }
        })
      }).catch(() => {})
    },
    // 单条确认送达
    handleSend(row) {
      this.currRow = row;
      this.dialogVisibleBill = true;
      this.tableData = [row];
      this.sendType = 1; // 1:单条送达操作
    },
    // 确认配送
    handleDistr(row) {
      // 有配送员的才能点击开始配送，没有配送员的不可点击开始配送
      if(!!row.rider_name) {
        this.setConfirm(
          `确认开始配送吗?`,
          {  id: row.id },
          "/admin/order/startDelivery"
        );
      } else {
        this.$message.warning("该订单未绑定配送员，请点击未绑定进行分配配送员")
      }
    },
    // 修改配送日期
    handleDate(row,index) {
      if(!this.form.data.distr_date) {
        this.$message.warning("请选日期")
        return
      }
      this.$http.post('/admin/order/updateDelivery', {id:row.id,distr_date:this.form.data.distr_date}).then(res => {
        if(res.code == 1) {
          this.form.data.distr_date = '';
          this.$message.success('操作成功！')
          this.$refs[`popoverDom+${index}`].doClose()
          this.$refs.elFormDom.resetFields()
          this.getTable();
        }
      })
    },

    // 批量方法
    batchFun(data) {
      this.$http.post(data.url, data.params).then(res => {
        if(res.code == 1) {
          this.$message.success('操作成功！')
          this.riderDialogVisible = false;
          this.dialogVisibleBill = false;
          this.getTable();
        }
      }).finally(() => {
        this.formBill.loading = false;
      })
    },
    // 批量分拣
    sortingBatch() {
      if(this.multipleSelection.length > 0) {
        let _ids = this.multipleSelection.map((item) => {
          return item.id
        }).join("|")
        let data = {
          url:'/admin/order/patchSort',
          params: {
            ids: _ids
          }
        }
        this.$confirm(`分拣完成订单数：${this.multipleSelection.length}，订单将全部分拣完成进入待配送`,'提示',{
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.batchFun(data)
        }).catch(() => {})
      } else {
        this.$message.warning('请先选择需要分拣的订单')
      } 
    },
    // 批量配送
    distrBatch() {
      if(this.multipleSelection.length > 0) {
        this.riderForm.data.rider_id = '';// 清空上一次选择的配送员
        let is_rider_name = this.multipleSelection.some((item) => !item.rider_name); // 检测提交的数据是有配送员
        if(is_rider_name == true) {
          this.$message.warning('订单缺少配送员，请先绑定配送员')
          this.riderDialogVisible = true;
        } else {
          this.submitRider();
        }
      } else {
        this.$message.warning('请先选择需要配送的订单')
      } 
    },
    // 批量配送弹窗提示处理
    submitRider() {
      let _ids = this.multipleSelection.map((item) => {
        return item.id
      }).join("|")
      let data = {
        url:'/admin/order/patchDelivery',
        params: {
          ids: _ids,
          rider_id: this.riderForm.data.rider_id || '', // 配送员id
        }
      }
      this.$confirm(`开始配送订单数：${this.multipleSelection.length}，订单将全部开始配送进入待送达`,'提示',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.batchFun(data)
      }).catch(() => {})
    },
    
    // 批量送达
    sendBatch() {
      this.sendType = 2; // 2：批量送达操作
      if(this.multipleSelection.length > 0) {
        this.dialogVisibleBill = true;
        this.tableData = this.multipleSelection;
      } else {
        this.$message.warning('请先选择需要送达的订单')
      }
    },
    // 上传凭证后确认送达
    sumbitBill() {
      this.$refs.elFormDomBill.validate(valid => {
        if(valid) {
          console.log(this.sendType,"this.sendType123")
          if(this.sendType == 1) { // 单条送达
            let _params = {
              id: this.currRow.id,
              finish_image: this.fileList.map(v => v.url).join("|") // 凭证
            }
            console.log(_params)
            this.formBill.loading = true;
            this.$http.post('/admin/order/complete', _params).then(res => {
              if(res.code == 1) {
                this.dialogVisibleBill = false;
                this.$message.success("上传成功！")
                this.getTable();
              }
            }).finally(() => {
              this.formBill.loading = false;
            })
          } else { // 批量送达
            let _ids = this.multipleSelection.map((item) => {
              return item.id
            }).join("|")
            let data = {
              url: '/admin/order/patchComplete',
              params:{
                ids: _ids,
                finish_image: this.fileList.map(v => v.url).join("|") // 凭证
              }
            }
            this.$confirm(`送达订单数：${this.multipleSelection.length}，订单将全部确认送达进入已完成 `,'提示',{
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.formBill.loading = true;
              this.batchFun(data)
            }).catch(() => {})
          }
        }
      })
    },
    // 图片上传成功
    handleAvatarSuccess(res, file) {
      this.formBill.data.bills = res.data.all_path_url;
      let strImg = {
        url: res.data.all_path_url,
        name: ''
      }
      this.fileList.push(strImg)
      this.$refs.elFormDomBill.clearValidate();
    },
    handleRemove(file, fileList) {
      if(this.fileList.length > 0) {
        this.fileList.forEach((v, i) => {
          if(file.uid === v.uid) {
            this.fileList.splice(i,1)
          }
        })
      }
    },
    // 图片上传之前
    beforeAvatarUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.warning('上传图片大小不能超过 20MB!');
      }
      return isLt20M;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisibleUrl = true;
    },
    // 操作
    handleCommand(value,row) {
      console.log(value,"value")
      // return
      if(value == 'copy') { // 复制
        this.showAdd(row)
      } else if(value == 'export') { // 导出
        this.singleExport(row)
      } else if(value == 'repair') { // 补单 (线上支付的订单不支持帮客户修改数量和补单)
        if(row.order_type == 0) {
          this.showRepair(row)
        } else {
          this.$message.warning('线上支付订单不支持操作')
        }
      } else if(value == 'cancel') { // 取消
        this.handleCancel(row)
      } else if(value == 'remove') { // 删除
        this.remove(row)
      }
    },
    // 单条审核
    handleCheck(row) {
      this.checkType = 1;
      this.currRow = row;
      this.dialogVisibleCheck = true;
    },
    selectEnable(row) {
      // is_check是否审核 1:是 2:否 已审核的不可以选
      if(row.is_check == 2) {
        return true
      }
    },
    // 订单批量审核
    checkBatch() {
      this.checkType = 2; // 2：批量审核操作
      this.$nextTick(() => {
        this.$refs.tableDom.doLayout();
      })
      if(this.multipleSelection.length > 0) {
        let arr = this.multipleSelection.filter(j => j.is_check == 2); // 过滤掉已审核的订单
        console.log(arr,"arr")
        if(arr.length < 1) {
          this.$message.warning('您勾选的订单已经审核过')
          return;
        }
        this.dialogVisibleCheck = true;
        this.check_addressNUm = arr.filter((item, index, self) =>
          self.findIndex((i) => i.address_id === item.address_id) === index
        ); // 食堂数量（相同食堂去重）
        this.check_detailsNum = this.countNum(arr); // 商品项数合计
        this.check_spec_count = this.countNum2(arr); // 下单数量合计
      } else {
        this.$message.warning('请先选择需要审核的订单')
      }
    },
    countNum(arr) {
      let sum = 0;
      arr.map(function (item) {
        sum += Number(item.details.length)
      })
      return sum.toFixed(2) * 1
    },
    countNum2(arr) {
      let sum = 0;
      arr.map(function (item) {
        sum += Number(item.spec_count)
      })
      return sum.toFixed(2) * 1
    },
    sumbitCheck() {
      let _ids = ''
      if(this.checkType == 1) {
        _ids = this.currRow.id;
      } else {
        let arr = this.multipleSelection.filter(j => j.is_check == 2); // 过滤掉已审核的订单
        _ids = arr.map(v => { return v.id }).join("|")
      }
      this.$http.post('/admin/order/check',{ids: _ids}).then(res => {
        if(res.code == 1) {
          this.$message.success("操作成功！")
          this.getTable();
          this.dialogVisibleCheck = false;
          this.checkType = 1; // 审核方式默认1
          this.multipleSelection = [];
        }
      })
    },
    // 导出文本
    exportText() {
      if(this.multipleSelection.length > 0) {
        let ids = this.multipleSelection.map((item) => {
          return item.id
        }).join("|")
        this.$http.get('/admin/order/exportText',{params:{order_ids: ids}}).then(res => {
          if(res.code == 1) {
            const text = res.data.str; // 需要下载的文本内容
            const blob = new Blob([text], {type:'text/plain'});
            // 创建a标签元素
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob)
            link.download ='订单商品.txt';
            // 添加到页面上进行点击下装
            document.body.appendChild(link);
            link.click();
            // 移除临时生成的a标签无素
            document.body.removeChild(link);
          }
        })
      } else {
        this.$message.warning("请先选择需要导出的订单")
      }
    },
    // 导出模板
    confirmExport() {
      if (!!this.tpl_id) {
        let order_ids = this.multipleSelection
          .map((item) => {
              return item.id;
          }).join("|");
        let str = ""
        let obj = {
          order_ids,
          tpl_id: this.tpl_id, // 模板id
        }
        Object.keys(obj).forEach(item => {
          str += `&${item}=${obj[item]}`
        })
        let url = '/admin/excel/export';
        console.log(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
        window.open(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
        this.dialogVisible = false;
        this.tpl_id = '';
      } else {
        this.$message.warning("请选择导出模板");
      }
    },
  },
  beforeUpdate() {
    this.$nextTick(() => {
      this.$refs.tableDom.doLayout();
    })
  }
};
</script>
<style scoped>
/* .el-table .success-row {
  background: #eaf7f0;
  color: #595959;
  border: none;
}
.el-table .success-row:hover {
  background: #eaf7f0;
}
.el-table .success-row ::v-deep .el-table--border .el-table__cell {
  border-right: none !important;
} */
.mr20 {
  margin-right: 20px;
}
.chTable .el-table__expanded-cell {
  padding: 0 !important;
}
.curTableWrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 24px;
  background: #fff;
  flex: 1;
  overflow-y: scroll;
}
.pagination__wrap {
  margin: 10px 0;
  text-align: right;
  /* padding-right: 40px; */
}
.goodsbox {
  width: 10%;
  display: inline-block;
}
.yellow {
  color: #FF803B;
}
.item {
  text-align: center;
}
.item-card {
  margin-bottom: 10px;
  cursor: pointer;
  box-shadow: 0 2px 12px 0 #eaf7f0;
}
.imgWrap ::v-deep .el-upload--picture-card {
  width: 80px !important;
  height: 80px !important;
  line-height: 80px !important;
}
.imgWrap ::v-deep .el-upload-list__item{
  width: 80px !important;
  height: 80px !important;
}
</style>

<style media="print" lang="scss">
// 分拣组件的打印样式
.printContent {
  display: none;
}
.print-title {
  padding: 5px;
  margin: 5px;
  border-bottom: 1px solid #000;
}
.print-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 5px;
}
.print-con {
  padding: 3px 0;
}
.print-text {
  margin: 0 5px;
}

@page {
  size: auto;
  margin: 0 2mm;
}
@media print {
  html {
    background-color: #ffffff;
    height: auto;
    margin: 0;
  }
  body {
    border: solid 1px #ffffff;
    margin: 0 5px;
    padding: 0 5px;
    font-size: 10px;
  }
  .printContent {
    margin: 0 5px;
  }
  .print-title{
    font-size: 18px;
    margin-top:0;
    margin-bottom: 5px;
    padding: 5px;
  }
  .print-con {
    font-size: 16px;
  }
  .layout__wrap {
    height: auto !important;
  }
  .main-view {
    height: auto !important;
  }
  .printContent {
    display: block;
  }
}
.el-dropdown-link {
  cursor: pointer;
  color: #2cb167;
  font-size: 12px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.mt10 {
  margin-top:10px;
}
.tpl-action {
  border: solid 1px #2cb167;
}
</style>