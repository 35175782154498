<template>
  <!-- 补单 -->
  <div class="addPerson__wrap" v-if="visible">
    <el-header class="shadow page-header__wrap">
      <div class="flex">
        <div class="backBtn" @click="handleReturn">
          <el-image class="back-img" :src="require('@/assets/img/back.png')"></el-image>
          <span>返回</span>
        </div>
        <div class="title">
          <span class="site">当前位置：</span>
          {{ $route.meta.title }}
          <span> > </span>
          补单
        </div>
      </div>
    </el-header>

    <div class="main">
      <div class="mainBox">
        <el-form
          class="staff-from"
          v-loading="loading"
          element-loading-text="加载中…"
          ref='elFormDom'
          label-width='90px'
          label-position='right'
          :model='form.data'
          :rules='form.rules'
          :size="subUnitSize"
          :inline="true"
        >
          <el-row>
            <el-col :span="24">
              <div class="itemTime" style="float: left;">基础信息</div>
              <!-- 补订单不允许修改 -->
              <el-form-item label="客户" prop="merchant_name">
                <el-input v-model="form.data.merchant_name" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="下单食堂" prop="address_name">
                <el-input v-model="form.data.address_name" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="配送日期" prop="distr_date">
                <el-date-picker
                  v-model="form.data.distr_date"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                  :disabled="true"
                  >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div class="itemTime" style="float: left;">收货信息</div>
              <el-form-item label="联系人" prop="contact_name">
                <el-input
                  v-model.trim="form.data.contact_name"
                  :disabled="true"
                ></el-input>
              </el-form-item>
              <el-form-item label="联系电话" prop="contact_phone">
                <el-input
                  v-model.trim="form.data.contact_phone"
                  maxlength="11"
                  :disabled="true"
                ></el-input>
              </el-form-item>
              <el-form-item label="详细地址:" prop="address" >
                <el-input
                  v-model.trim="form.data.address"
                  :disabled="true"
                  style="width:195px;"
                  type="textarea"
                  :autosize="{ minRows: 1, maxRows: 3}"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="itemTime">商品清单</div>
        <el-button type="primary" class="mb20" plain size="small" @click="batchAdd">批量添加</el-button>
        <el-button class="mb10" type="primary" plain size="small" @click="batchImport">批量导入</el-button>
        <el-button class="mb10" type="warning" plain size="small" @click="imageImport">图片识别</el-button>
        <el-button class="mb10" type="warning" plain size="small" @click="textImport">复制文字</el-button>
        <el-table
          :data="tableData"
          :header-cell-style="{background:'#f5f5f5'}"
          style="width: 100%"
          border
          >
          <el-table-column
            width="80">
            <template slot-scope="{}">
              <i class="el-icon-menu"></i>
            </template>
          </el-table-column>
          <el-table-column
            width="80">
            <template slot="header" slot-scope="{}">
              <i class="el-icon-s-fold"></i>
            </template>
            <template slot-scope="{row, $index}">
              <el-popconfirm title="确认删除此商品？" @confirm="onMinus(row,$index)">
                <el-button slot="reference" plain style="width: 22px;height: 18px;padding: 0;">-</el-button>
              </el-popconfirm>
              <el-button plain style="display:block;width: 22px;height: 18px;padding: 0;" @click="onAdd(row,$index)">+</el-button>
            </template>
          </el-table-column>
          <el-table-column
            prop="good_img"
            label="商品图片"
            width="80px">
            <template slot-scope="{row}">
              <el-image
                v-if="!!row.image"
                style="width: 40px; height: 40px"
                :src="row.image"
                fit="cover"
                :preview-src-list="[row.image]"></el-image>
              <el-image
                v-else
                style="width: 40px; height: 40px"
                :src="require('@/assets/img/logo-icon.jpeg')"
                fit="cover"
                :preview-src-list="[require('@/assets/img/logo-icon.jpeg')]"></el-image>
            </template>
          </el-table-column>
          <el-table-column
            prop="good_name"
            label="商品名称"
            width="180">
            <template slot-scope="{row,$index}">
              <el-select
                v-model="row.good_name"
                filterable
                remote
                placeholder="请输入关键词"
                :remote-method="remoteMethod"
                :loading="selLoading"
                :ref="`multiSelect+${$index}`"
                size="small"
                @change="changeGoods($event,$index)" value-key="id">
                <el-option v-for="item in goodArr" :label="item.good_name" :value="item" :key="item.id" :disabled="item.sold_out == 1 ? true : false">
                  <div class="flex" style="justify-content: space-between;">
                    <div>
                      <span style="float: left">{{ item.good_name }}</span>
                      <span v-if="item.sold_out == 1" style="color:#f56c6c; font-size: 12px">(售罄)</span>
                    </div>
                    <span style="color: #8492a6; font-size: 13px">{{ item.name }}</span>
                  </div>
                  <div style="color:#999;margin-top: -5px;" v-if="!!item.norm_name">标名：{{item.norm_name}}</div>
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            prop="price"
            label="售价/元"
            width="130px">
            <template slot-scope="{row}">
              <div v-if="!!row.price">¥{{row.price}}</div>
              <div v-if="!!row.spec_amount" style="color: #a1a1a1;font-size: 12px;">(参考价¥ {{row.spec_amount}})</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="unit_name"
            label="单位">
          </el-table-column>
          <el-table-column
            prop="name"
            label="规格"
            width="180">
            <template slot-scope="{row,$index}">
              <el-select
                v-if="row.type == 2"
                v-model="row.name"
                size="mini"
                @change="changeSpec($event,$index)" value-key="id">
                <el-option v-for="(v,i) in row.spec" :label="v.name" :value="v" :key="i"></el-option>
              </el-select>
              <span v-else>{{row.name}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="num"
            label="*下单数量"
            width="200">
            <template slot-scope="{row,$index}">
              <div style="display:flex;align-items: center;">
                <el-input  v-model="row.num" placeholder="请输入" size="small" style="width:120px;margin-right:5px;" @input="countPrice(row,$index)"></el-input>
                <span v-if="row.newNum > 0" style="color:red;width:54px;">+{{row.newNum}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="price_curr"
            label="小计"
            width="100px">
            <template slot-scope="{row}">
              <span>{{row.price_curr || 0}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="remark"
            label="备注"
            min-width="120">
            <template slot-scope="{row}">
              <el-input v-model="row.remark" placeholder="请输入" size="small"></el-input>
            </template>
          </el-table-column>
        </el-table>

        <div class="itemTime mt20">其他信息</div>
        <el-upload
          class="upload-demo"
          action="/api/common/qiniu/uploadFile"
          :limit="1"
          :headers="{ token: token }"
          :on-success="handleAvatarSuccess"
          :on-remove="handleRemove"
          :on-progress="handleUpload"
          :file-list="fileList"
          accept="image/jpeg, image/png, image/gif, .pdf, .xlsx, .xls "
          >
          <el-button size="small" type="primary" plain>点击上传</el-button>
        </el-upload>
      </div>
      <div class="footerWrap">
        <el-button size="medium" @click="handleReturn">取消</el-button>
        <el-button type="primary" size="medium" :loading="form.loading" @click="confirm">保存</el-button>
      </div>
    </div>

    <!-- 同一食堂添加重复商品 -->
    <el-dialog
      title="该食堂今日已下单该商品规格，确定提交吗?"
      :visible.sync="dialogVisible"
      width="500px"
      :before-close="handleClose"
      append-to-body
      center>
      <div style="font-weight:bold;"></div>
      <div style="display:flex;flex-wrap: wrap;">
        <div v-for="(v,i) in diff_spec" :key="i" style="margin-right:20px;margin-bottom:10px;">
          <span>{{v.good_name}}({{v.spec_name}})</span>
          <span v-if="i < diff_spec.length - 1">,</span>
        </div>
      </div>
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="commitFun(curr_params)">提交订单</el-button>
      </span>
    </el-dialog>

    <!-- 批量选择商品弹窗 -->
    <BatchGoods ref="batchGoods" :merchant_id="form.data.merchant_id" :address_id="form.data.address_id" @sendgoodslist="harvestgoodslist" v-if="isShowDialog"></BatchGoods>
  
    <!-- 导入文件弹窗 -->
    <ImportDailog ref="importDailog" @sendgoodslist="harvestgoodslist"></ImportDailog>

    <!-- 导入图片弹窗 -->
    <ImportImage ref="importImage" @sendgoodslist="harvestgoodslist"></ImportImage>

    <!-- 导入文字弹窗 -->
    <ImportText ref="importText" @sendgoodslist="harvestgoodslist"></ImportText>

  </div>
</template>

<script>
  import BatchGoods from "@/layout/BatchGoods"
  import ImportDailog from './importDailog.vue'
  import ImportImage from './importImage.vue'
  import ImportText from './importText.vue'
  import { mixinTable } from "@/mixins/table.js";
  import { setStorage,getStorage,remove } from '@/storage'
  export default {
    name: 'orderRepair',
    props:['merchantArr','sampleFile','const_merchant_id'],
    mixins: [mixinTable],
    components: {
      BatchGoods,
      ImportDailog,
      ImportImage,
      ImportText,
    },
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        table:{
          params: {
            page:1,
            count: 10,
          },
          total: 0,
          data:[],
        },
        tableData: [{id:'',name:'',type:1}],
        specArr: [],
        
        loading: false,
        form: {
          loading: false,
          data: {
            merchant_id: '',
            merchant_name: '',
            address_name: '',
            distr_date: '',
            contact_name: '',
            contact_phone: '',
            address: '',
            file: '', // 附件
          },
          rules: {
            
          },
        },
        fileList: [],
        isShowDialog: false,
        goodArr: [], // 商品列表
        selLoading: false,
        orderId: '', // 订单id

        dialogVisible: false,
        diff_spec: 0,
        curr_params: {},
        merchantEnableArr:[],
        point: 2,//售价小数点位 0:无小数 1:1位 2:2位 3:3位
        point_rule: 1,//小数规则 1:四舍五入 2:直接抹去
      }
    },
    created() {
      this.form.data.distr_date = this.$moment(new Date().getTime() + 60 * 60 * 24 * 1000).format("YYYY-MM-DD") // 默认明天
    },
    methods: {
      // 添加订单、采购单、盘点单，列表输入商品名称查询商品
      remoteMethod(query) {
        if(query !== '') {
          let _params = {
            page: 1,
            count: 1000,
            status:1,
            good_name: query,
            merchant_id: this.form.data.merchant_id,
            address_id: this.form.data.address_id,
          }
          this.selLoading = true;
          this.$http.get('/admin/goods/specList', { params:_params}).then(res => {
            if(res.code === 1) {
              this.goodArr = res.data.list;
            }
          }).finally(() => {
            this.selLoading = false;
          })
        } else {
          this.goodArr = [];
        }
      },
      getDetail(row) {
        this.merchantEnableArr = this.merchantArr.filter(item => item.status == 1 && item.order_type == 1); // 过滤出合作中的客户，并且是线下结算的客户
        this.orderId = row.id; // 订单id
        if(!!row.id) {
          this.isChange = true;
          // 要补单的订单详情
          this.$http.post('/admin/order/detail', { id: row.id }).then(res => {
            if(res.code == 1) {
              this.form.data.merchant_id = res.data.merchant_id; // 客户id,添加的商品需要根据客户来查
              this.form.data.merchant_name = res.data.merchant_name; // 客户名称
              this.form.data.address_name = res.data.address_name; // 食堂名称
              this.form.data.distr_date = this.$moment(new Date().getTime() + 60 * 60 * 24 * 1000).format("YYYY-MM-DD") // 配送日期默认推后一天
              this.form.data.contact_name = res.data.name; // 联系人
              this.form.data.contact_phone = res.data.phone; // 联系电话
              this.form.data.address = res.data.address;
              this.form.data.address_id = res.data.address_id;
              this.point = this.merchantEnableArr.filter(v => v.id == res.data.merchant_id)[0].point;//客户售价小数点位 0:无小数 1:1位 2:2位 3:3位
              this.point_rule = this.merchantEnableArr.filter(v => v.id == res.data.merchant_id)[0].point_rule;//客户小数规则 1:四舍五入 2:直接抹去
            }
          })
        }
        let _cacheData = getStorage(`orderAddInfo_${this.orderId}`)
        if(!!_cacheData) {
          this.tableData = _cacheData;
        }
      },
      reset(done) {
        this.isChange = false
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 减
      onMinus(row,index) {
        console.log(row,index)
        if(this.tableData.length > 1) {
          this.tableData.forEach((v,i) => {
            if(index == i) {
              this.tableData.splice(i, 1)
            }
          })
          this.specArr = this.specArr.filter(j => j != row.id);
        } else {
          this.$message.warning('最后一条数据了!')
        }
      },
      onAdd(row,index) {
        let str = {
          image: require('@/assets/img/default.jpg'),
          good_name:'',
          id: '',
          num:'',
          remark:'',
          type:1, // 类型 1:文本 2:下拉框
        }
        this.tableData.splice(index+1,0,str); // 添加到指定位置
        this.$set(this.tableData,row,index)
        this.$nextTick(() => {
          this.$refs[`multiSelect+${index + 1}`].focus()
        })
        this.goodArr = []; // 清空上次查询的商品数据
      },
      // 保存补单
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let currArr = this.tableData.filter(j => j.id !== '' ) // 过滤掉列表中未选商品的空的数据
            if(currArr.length == 0) {
              this.$message.warning('请先添加商品')
              return
            }
            let _spec = currArr.map(v => {
              return {
                spec_id: v.id,
                num: v.num,
                remark: v.remark || ''
              }
            })
            console.log(_spec)
            let _params = {
              id: this.orderId, // 订单id
              file: this.form.data.file,
              spec: _spec
            }
            console.log(_params)
            // 验证规格必选
            let is_specid = _spec.some((item) => item.spec_id == 0);
            if(is_specid) {
              this.$message.warning("请选择规格！")
              return
            }
            // 验证数量必填
            let is_num = _spec.some((item) => !item.num);
            if(is_num) {
              this.$message.warning("请输入商品下单数量！")
              return
            }
            console.log(_params)
            let _spec_id = currArr.map(j => {
              return {
                spec_id: j.id
              }
            })
            let _obj = {
              address_id: this.form.data.address_id,
              spec:  _spec_id
            }
            // 判断同一食堂是否重复下同一商品，或下相同的订单
            this.form.loading = true;
            this.$http.post('/common/subject/todayOrder',_obj).then(res => {
              if(res.code == 1) {
                if(res.data.is_diff == 1) {// 今日重复订单
                  this.$confirm(`订单重复，订单号:${res.data.order_no},确认下单吗?`,"提示", {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                  }).then(() => {
                    this.commitFun(_params)
                  }).catch(() => {})
                } else if(res.data.diff_spec.length > 0) { // 今日有重复规格
                  this.diff_spec = res.data.diff_spec;
                  this.dialogVisible = true;
                  this.curr_params = _params;
                } else { // 正常下单
                  this.commitFun(_params)
                }
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      commitFun(_params) {
        this.form.loading = true;
        this.$http.post('/admin/order/replenish', _params).then(res => {
          if(res.code == 1) {
            remove(`orderAddInfo_${this.orderId}`)
            this.toggle(false)
            this.$emit("refresh")
            this.$message.success('操作成功！')
            this.tableData = [{id:'',name:'',type:1}]
            this.specArr = [];
            this.handleClose(); // 关闭弹窗
          }
        }).finally(() => {
          this.form.loading = false;
        })
      },
      // 图片上传之前
      beforeAvatarUpload(file) {
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
          this.$message.error('上传图片大小不能超过 10MB!');
        }
        return isLt10M;
      },
      // 图片上传成功
      handleAvatarSuccess(event) {
        this.loading = false;
        if (event.code == 1) {
          this.form.data.file = event.data.all_path_url;
        } else {
          this.$message({
            type: "error",
            message: event.msg,
          });
        }
      },
      // 文件上传时的钩子
      handleUpload() {
        this.loading = true;
      },
      // 批量添加弹窗
      batchAdd() {
        this.isShowDialog = true
      },
      // 计算小计
      subtotalFun(price, quantity) {
        let price_curr = 0;
        if(this.form.data.merchant_id == this.const_merchant_id) {
          // 判断选择的商户如果是省委党校，则计算出来的小计最多需要保留三位数
          return price_curr = Number((price * quantity).toFixed(3))
        } else {
          // formatDecimal保留小数方法,参数1:金额总数，参数2：需保留的小数位数，参数3：(1:四舍五入，2：直接舍去)
          console.log(this.point,"point")
          console.log(this.point_rule,"point_rule")
          return price_curr = this.formatDecimal(((price * quantity)*100)/100,this.point,this.point_rule)
        }
        // return price_curr = this.form.data.merchant_id == this.const_merchant_id ? Number((price * quantity).toFixed(3)) : Math.round(parseFloat(price * quantity)*100)/100 // 计算小计
      },
      // 接受子组件传过来的商品列表
      harvestgoodslist(val) {
        let newSpecid = []
        newSpecid = this.tableData.map(v => { return v.id })
        this.specArr = newSpecid;
        val.forEach(item => {
          if(this.specArr.indexOf(item.id) >= 0){
            this.tableData.map(value => {
              if(value.id == item.id){
                value.num = (Number(value.num) + Number(item.num)).toFixed(1) * 1;
                value.price_curr = this.subtotalFun(value.price,value.num);  // 计算小计;
                // value.price_curr = Math.round(parseFloat(value.price * value.num)*100)/100;
                value.newNum = item.num; //要显示当前加的数量
              }
            });
          }else{
            this.tableData.push({
              ...item,
              price_curr: this.subtotalFun(item.price,item.num),
              // price_curr: Math.round(parseFloat(item.price * item.num)*100)/100,
            });
            if(item.id > 0) {
              this.specArr.push(item.id);
            }
          }
        });
        //this.tableData = this.tableData.concat(arr)
        console.log(this.tableData)
        this.isShowDialog = false;
      },

      // 导入商品，多规格时选择规格
      changeSpec(value,index) {
        if(this.specArr.indexOf(value.id) >= 0) {
          this.$message.warning('已经添加过该规格')
          this.tableData[index].name = ''; // 当前行规格值清空
          this.tableData[index].id = 0; // 当前行规格值清空
          return
        } else {
          if(!this.form.data.address_id) {
            this.$message.warning('请选择下单食堂')
            return
          }
          let _params = {
            address_id: this.form.data.address_id,
            spec: [
              {
                spec_id: value.id, // 规格id
              }
            ]
          }
          // 判断同一食堂是否重复下同一商品
          this.$http.post('/common/subject/todayOrder',_params).then(res => {
            if(res.code == 1) {
              if(res.data.diff_spec.length > 0) {
                this.$confirm("该食堂今日已下单该商品规格，确定添加吗","",{
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  this.$nextTick(() => {
                    this.specArr.push(value.id);
                    let _currRow = {
                      id:value.id,
                      name:value.name,
                      price:value.price,
                      spec_amount:value.refer_price,
                      unit_name: value.unit_name,
                      type:1,
                      price_curr: this.subtotalFun(value.price,this.tableData[index].num), // 售价*下单数量=小计
                      // price_curr: Math.round(parseFloat(value.price * this.tableData[index].num)*100)/100 // 售价*下单数量=小计
                    }
                    Object.assign(this.tableData[index], _currRow)
                  })
                }).catch(() => {
                  this.tableData[index].name = {}; // 当前行规格清空
                  return; 
                })
              } else {
                this.specArr.push(value.id);
                let _currRow = {
                  id:value.id,
                  name:value.name,
                  price:value.price,
                  spec_amount:value.refer_price,
                  unit_name: value.unit_name,
                  type: 1,
                  price_curr: this.subtotalFun(value.price,this.tableData[index].num), // 售价*下单数量=小计
                  // price_curr: Math.round(parseFloat(value.price * this.tableData[index].num)*100)/100 // 售价*下单数量=小计
                }
                Object.assign(this.tableData[index], _currRow)
              }
            }
          })
        }
      },

      // 选择商品
      changeGoods(value, index) {
        // this.tableData[index] = Object.assign(value,{remark:''})
        let newSpecid = []
        newSpecid = this.tableData.map(v => { return v.id })
        this.specArr = newSpecid;
        if(this.specArr.indexOf(value.id) >= 0) {
          this.$message.warning("已经添加过该规格");
          this.tableData[index].good_name = ''; // 当前行搜索值清空
          this.goodArr = []; // 当前行筛选数据清空
          return; 
        } else {
          let _params = {
            address_id: this.form.data.address_id,
            spec: [
              {
                spec_id: value.id
              }
            ]
          }
          // 判断同一食堂是否重复下同一商品
          this.$http.post('/common/subject/todayOrder',_params).then(res => {
            if(res.code == 1) {
              if(res.data.diff_spec.length > 0) {
                this.$confirm("该食堂今日已下单该商品规格，确定添加吗","",{
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  this.$nextTick(() => {
                    this.specArr.push(value.id);
                    let _currRow = {...value,num: ''};
                    this.tableData[index] = _currRow;
                    this.$set(this.tableData, index, {..._currRow})
                    this.goodArr = [];
                    this.$refs[`multiSelect+${index}`].blur();
                  })
                }).catch(() => {
                  this.tableData[index].good_name = {}; // 当前行搜索值清空
                  this.goodArr = []; // 当前行筛选数据清空
                  return; 
                })
              } else {
                this.specArr.push(value.id);
                let _currRow = {...value,num: ''};
                this.tableData[index] = _currRow;
                this.$set(this.tableData, index, {..._currRow})
              }
            }
          })
        }
      },
      // 移除图片
      handleRemove(file, fileList) {
        this.form.data.file = "";
        this.fileList = [];
      },
      // 计算价格
      countPrice(row,index) {
        const reg = /^[\d]+$/;
        // 下单数量单位只有是（克、两、斤、千克）的时候可以输小数点一位小数，其他的请控制不允许输小数点
        if(row.unit_name == '克' || row.unit_name == '两' || row.unit_name == '斤' || row.unit_name == '千克') {
          if (row.num && !reg.test(row.num)) {
            row.num = row.num=row.num.match(/\d+(\.\d{0,1})?/) ? row.num.match(/\d+(\.\d{0,1})?/)[0] : '';
          }
        } else{
          if (row.num && !reg.test(row.num)) {
            row.num = row.num.replace(/[^\d]/g, '');
          }
        }
        if(!!row.num) {
          row.price_curr = this.subtotalFun(row.price,row.num);
          // row.price_curr = Math.round(parseFloat(row.price * row.num)*100)/100;
          this.$set(this.tableData, index, {...row,num:row.num})
        }
      },
      handleClose() {
        this.dialogVisible = false;
        this.curr_params = {}
      },
      // 返回
      handleReturn() {
        // 判断是否有添加补单商品，如果添加了商品信息则保存草稿
        let arr = this.tableData.filter(v => !!v.good_name);
        if(arr.length > 0) {
          setStorage(`orderAddInfo_${this.orderId}`, arr)
        }
        this.$emit("refresh")
        this.toggle(false)
        this.goodArr = []; // 清空上次查询的商品数据
        this.tableData = [{id:'',name:'',type:1}]
      },
      //批量导入
      batchImport() {
        let dom = this.$refs.importDailog;
        dom.toggle(true);
        let obj = {
          sampleFile: this.sampleFile, // 样表
          merchant_id: this.form.data.merchant_id,
        }
        dom.getDetail(obj);
        dom = null;
      },
      // 图片导入识别
      imageImport() {
        let dom = this.$refs.importImage;
        dom.toggle(true);
        let obj = {
          sampleFile: this.sampleFile, // 样表
          merchant_id: this.form.data.merchant_id,
        }
        dom.getDetail(obj);
        dom = null;
      },
      // 复制文字识别
      textImport() {
        let dom = this.$refs.importText;
        dom.toggle(true);
        let obj = {
          sampleFile: this.sampleFile, // 样表
          merchant_id: this.form.data.merchant_id,
        }
        dom.getDetail(obj);
        dom = null;
      }
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
} 

.footerWrap {
  text-align: center;
  padding: 10px;
  box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
  z-index: 99;
}
.itemTime {
  display: block;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
}
.itemTime::before {
  content: "";
  width: 5px;
  height: 15px;
  background: #2cb167;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.flex {
  display: flex;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.width200px {
  width: 200px;
}
.after-table {
  padding: 10px;
  font-size: 13px;
  color: #303030;
  text-align: right;
}
.el-select-dropdown__item {
  display: flex;
  flex-direction: column;
  height: auto !important;
}
</style>



